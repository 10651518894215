// Launching Soon

#launching-soon-text {
  max-width: 41em;
  max-height: 70vh;
  animation: fade-reveal-left 1s ease-in-out;
}

#rocket-ls {
  position: relative;
  animation: fade-reveal-up 1s ease-in-out;
  width: calc(15vw + 10vh);
  max-width: 300px;

  & svg {
    display: block;
  }

  & .boosters,
  .shuttle,
  .icon {
    width: 100%;
    height: 100%;
    animation: shake 0.5s ease-in-out infinite alternate;
  }

  & .shuttle,
  .icon,
  .trail-booster {
    position: absolute;
    top: 0;
  }

  & .trail-booster {
    filter: blur(1px) drop-shadow(0 0 15px white);
    width: 100%;

    & .trail-stop-start {
      stop-color: #FFFFFF88;
    }

    & .trail-stop-end {
      stop-color: #FFFFFF73;
      animation: trail-smoke 0.1s infinite alternate;
    }

    & .trail-path {
      fill: url(#trail-booster-gradient);
    }
  }
}

@keyframes shake {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(4px);
  }
}

@keyframes trail-smoke {
  from {
    stop-color: #FFFFFF73;
  }
  to {
    stop-color: #FFFFFF88;
  }
}

@keyframes fade-reveal-up {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
}