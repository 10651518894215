// About

#about-container {
  height: calc(100vh - 70px);
  margin-top: 70px;
  animation: fade-reveal 1s 300ms ease-in-out backwards;
}

.about-card-col {
  min-height: 500px;
}

// Limit height at medium breakpoint
@include media-breakpoint-down(lg) {
  .about-card-col {
    max-height: 500px;
  }
}

#log-text {
  font-size: 12px !important;
  color: $bodyColor;
}

#log-error {
  color: $danger;
}

.nav-pills-ml {
  @extend .nav-pills;

  --#{$prefix}nav-pills-link-active-color: #{lighten($bodyColor, 10%)};
  --#{$prefix}nav-pills-link-active-bg: #{$lightSpace};

  & .nav-link {
    color: $bodyColor;
    padding: .25rem .5rem;
    font-size: $font-size-sm;
    border-radius: $border-radius-sm;

    &:hover {
      color: lighten($bodyColor, 10%);
    }

    & .active,
    .show > .nav-link {
      color: lighten($bodyColor, 10%);
    }
  }
}

#compile-button {
  min-width: 34px;
}

#pdfbox {
  position: relative;
  transition: opacity $transitionNormal ease-in-out;
  z-index: 5;
}

#pdf-compile-alert {
  position: absolute;
  top: 4em;
  opacity: 0.85;
  z-index: 5;
}

#pdf-pregen-alert {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.85;
  margin: 17px;
  z-index: 5;
}

.pdf-loading {
  opacity: 0;
}

#pdfbox-spinner {
  z-index: 4;
  position: absolute;
}

a {
  color: shift-color($bodyColor, -100%);
  text-decoration: none;
  transition: color $transitionNormal ease-in-out;

  &:hover,
  &:focus {
    color: $danger;
  }

  &:active {
    color: darken($danger, 10%);
  }
}