// Dev tag

#dev-tag-container {
  width: 100%;
  height: 100%;
}

.dev-tag {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999999;
  margin: 17px;
  opacity: 0.5;
}